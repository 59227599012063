<div [formGroup]="form" class="auth-input-field">
  <div class="input-field">
    <label *ngIf="!isAgreement()" class="label"
           [ngClass]="{'label--focus': focusField,
                       'label--warn': warnField && showValidation,
                       'label--valid': !warnField && showValidation}">
      <div *ngIf="displayLeftIco" class="input-ico-wrap">
        <app-icon width="24" height="24" file="auth" [icon]="type"></app-icon>
      </div>
      <input *ngIf="!isPhone() && !isCountry()"
             [type]="showPassword ? 'text' : type"
             [attr.data-test]="dataTestName"
             [formControlName]="controlName" (change)="onChange($event)"
             (focusin)="focusField = true"
             (focusout)="focusField = false"
             [placeholder]="placeholderValue() | translate"
             (keydown.enter)="onEnter.emit(true)"
             autocomplete="off webauthn"
             class="input normal">
      <ngx-intl-phone-input
        *ngIf="isPhone() && !isCountry()"
        [cssClass]="'custom'"
        [searchCountryFlag]="true"
        [exclusionarySearch]="true"
        [enablePlaceholder]="true"
        [selectFirstCountry]="false"
        [separateDialCode]="false"
        [selectedCountryISO]="countryCode"
        [phoneValidation]="true" [useMask]="true" [showMaskTyped]="true"
        [inputId]="'country-code-wrap'"
        [formControlName]="controlName"
      ></ngx-intl-phone-input>
      <div *ngIf="isPassword() && !isCountry()" class="input-ico-wrap" (click)="toggleShowPassword()">
        <app-icon width="24" height="24" file="auth"
                  class="input-ico input-ico--btn"
                  [icon]="showPassword ? 'eye-opened' : 'eye-closed'">
        </app-icon>
      </div>
      <on-ui-autocomplete *ngIf="isCountry()" [formControlName]="controlName" [class]="'transparent'"
                    [placeholder]="'profile-form.billing.residence_country' | translate"
                    [options]="options" (changed)="onChange($event)">
      </on-ui-autocomplete>
    </label>


    <label class="label terms"
    [ngClass]="{'label--focus': focusField,
    'label--warn': warnField && showValidation,
    'label--valid': !warnField && showValidation}"
    *ngIf="isAgreement()">
      <input type="checkbox"
            class="terms-checkbox" [formControlName]="controlName"
            (change)="onChange($event.target['checked'])">
      <span class="terms-text normal"
            [innerHTML]="placeholderValue() | translate">
      </span>
    </label>


    <ng-template [ngIf]="warnField && showValidation && message">
      <div class="warn-message normal">{{ message | translate }}</div>
    </ng-template>
  </div>
</div>
