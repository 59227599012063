<div class="back-btn" (click)="goBack()"></div>

<div *ngIf="step === 0" class="view">
    <ul class="simpleList">
        <li *ngFor="let item of roleTypes; trackBy: trackByFn"
            class="simpleList__item"
            (click)="set_role(item.role); step_next(item)">
          <div class="simpleList__icon">
            <app-icon width="48" height="150" file="role" [icon]="item?.icon_id"/>
          </div>
          <h4 class="simpleList__title">{{ item.label | translate }}</h4>
        </li>
    </ul>
</div>

<div *ngIf="step === 1" class="view">
    <app-auth-input-field class="form__input"
        [form]="form"
        type="name"
        [placeholder]="'auth.name'"
        controlName="first_name"
        [showValidation]="showValidation">
    </app-auth-input-field>

    <app-auth-input-field class="form__input"
        [form]="form"
        type="name"
        [placeholder]="'auth.surname'"
        controlName="last_name"
        [showValidation]="showValidation">
    </app-auth-input-field>

    <app-auth-input-field class="form__input"
        [form]="form"
        type="country"
        [options]="countriesList"
        [placeholder]="'profile-form.about.country'"
        controlName="country_id"
        [showValidation]="showValidation">
    </app-auth-input-field>

    <app-auth-input-field class="form__input"
        [form]="form"
        type="phone"
        [placeholder]="'auth.phone'"
        controlName="mobile_phone"
        [showValidation]="showValidation">
    </app-auth-input-field>

    <app-auth-input-field class="form__input"
        [form]="form"
        type="email"
        [placeholder]="'auth.email'"
        controlName="email"
        [showValidation]="showValidation">
    </app-auth-input-field>

    <app-auth-input-field class="form__input"
        [form]="form"
        type="password"
        [placeholder]="'auth.password'"
        controlName="password"
        [showValidation]="showValidation">
    </app-auth-input-field>

    <app-auth-input-field *ngIf="role === TEACHER"
        class="form__input"
        [form]="form"
        type="link"
        [placeholder]="'auth.account-name'"
        controlName="slug"
        [showValidation]="showValidation">
    </app-auth-input-field>

    <app-auth-input-field class="form__input"
        [form]="form"
        type="agreement"
        [placeholder]="'auth.signup.accept-text'"
        controlName="accept_sla"
        [showValidation]="showValidation">
    </app-auth-input-field>

    <!-- Captcha -->
    <div class="view__captcha"><div #Captcha action="signup"></div></div>

    <!-- Submit -->
    <button *ngIf="!captchaVisible"
            data-test="submit"
            type="button" class="button signup normal"
            (click)="submit()"> {{ 'auth.signup.action1' | translate }}
    </button>

    <!-- Error -->
    <div class="error-content" *ngIf="action_error">{{ action_error | translate }}</div>
</div>
