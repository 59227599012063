// @see https://www.npmjs.com/package/angularx-social-login
import {
  SocialAuthServiceConfig,
  FacebookLoginProvider,
  GoogleLoginProvider,
  GoogleInitOptions,
} from '@abacritt/angularx-social-login';

const googleLoginOptions: GoogleInitOptions = {
  scopes: ['profile', 'email'],
}; // https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig

const fbLoginOptions = {
  scope: 'public_profile,email',
  // scope: 'public_profile,first_name,last_name,name,id,picture.width(150).height(150),email',
  locale: 'en_US',
  // - настраивается тут https://developers.facebook.com/apps/447106395962762/app-review/permissions/',
  return_scopes: true,
  enable_profile_selector: true,
  fields: 'name,email,picture,first_name,last_name',
  version: 'v4.0',
}; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11

// eslint-disable-next-line max-len
// https://console.developers.google.com/apis/credentials/oauthclient/680468905548-063m8kpb85oj6p9686bsgajbdsvgbvr4.apps.googleusercontent.com?folder=&organizationId=&project=onclass-11649
export const GoogleClientId = '680468905548-063m8kpb85oj6p9686bsgajbdsvgbvr4.apps.googleusercontent.com';

// https://developers.facebook.com/apps/447106395962762/fb-login/settings/
export const FacebookAppId = '447106395962762';

const config: SocialAuthServiceConfig = {
  autoLogin: false,
  providers: [
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider(GoogleClientId, googleLoginOptions),
    },
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider(FacebookAppId, fbLoginOptions),
    },
  ],
};

export function authServiceConfigProvider() {
  return config;
}
