import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AuthService } from '@app/core/services';
import { PwdRestoreEndpoint } from 'lingo2-api-models';
import { ViewAuthDefaultComponent } from '../view-auth-default.component';

/*
 */
type TMode = 'email' | 'mobile_phone';
/*
 */
@Component({
  selector: 'view-auth-restore',
  templateUrl: './view-auth-restore.component.html',
  styleUrls: ['./view-auth-restore.component.scss'],
})
export class ViewAuthRestoreComponent extends ViewAuthDefaultComponent {
  public OTPLength = 5;
  public recoveryMode: TMode = 'email';
  public OTPError = false;
  public OTPInProgress = false;

  /*
   */
  constructor(protected fb: UntypedFormBuilder, private authService: AuthService) {
    super(fb, true, PwdRestoreEndpoint);
    this.set_max_steps(3);
  }

  /*
   */
  protected createForm(): void {
    this.form = this.fb.group({ [this.recoveryMode]: [''] });
    this.showValidation = false;
    this.action_error = null;
  }

  /*
   */
  public setRecoveryMode(mode: TMode) {
    this.form = null; // force gc
    this.recoveryMode = mode;
    this.createForm();
  }

  /*
   */
  public onOtpChange(event: string, mode: TMode) {
    if (event.length < this.OTPLength || this.OTPInProgress) {
      return;
    }

    this.OTPError = false;
    this.OTPInProgress = true;
    this.authService
      .resetPasswordConfirm({ otp: Number(event), id: this.step_payload?.id })
      .then(() => {
        this.OTPError = false;
        this.step_next(null);
      })
      .catch(() => {
        this.OTPError = true;
      })
      .finally(() => (this.OTPInProgress = false));
  }

  /*
   */
  public submit() {
    const val =
      this.form.controls[this.recoveryMode]?.value?.e164Number || this.form.controls[this.recoveryMode]?.value;
    if (!val || !val.length) {
      const err_message = [
        {
          code: 'custom',
          message: this.recoveryMode === 'mobile_phone' ? 'mobile_phone_invalid' : 'email_invalid',
          path: [this.recoveryMode],
        },
      ];
      this.on_err({ action: PwdRestoreEndpoint.action, error: err_message });
      return;
    }

    this.process_form({ [this.recoveryMode]: val }, (result) => {
      this.authService
        .resetPassword(result.payload as PwdRestoreEndpoint.Body, result?.captchaToken)
        .then((auth_res) => {
          this.step_next(auth_res);
          this.on_ok({ success: auth_res });
        })
        .catch((err) => this.on_err({ action: PwdRestoreEndpoint.action, error: err?.error?.validationError }))
        .finally(() => this.on_fin({}));
    });
  }

  /*
   */
  public goBack() {
    if (this.step > 0) {
      this.step_prev();
    } else {
      this.set_view('signin');
    }
  }
}
